import _ from "underscore";

import reduceTradesFilter, { TradesFilterEvents, TradesFilterStore } from "./reduce_trades_filter";
import reduceUniformatFilter, { defaultUniformatFilter, UniformatFilterEvents, UniformatFilterStore } from "./reduce_uniformat_filter";
import { Level1Id, Level2Id, Level3Id, Level4Id, UniformatId } from "uniformat";
import { DEVIATIONS_FROM_ALL_SCANS_SHOWN, DeviationsFromAllScansShownEvent, ONLY_DEVIATIONS_FROM_CURRENT_SCAN_SHOWN, OnlyDeviationsFromCurrentScanShownEvent } from "../../../events/viewer/deviations_current_scan_filter_toggled";
import { TOLERANCE_FILTER_CHANGED, ToleranceFilterChangedEvent } from "../../../events/viewer/tolerance_filter_changed";
import { NOT_BUILT_ELEMENTS_SHOWN, NotBuiltElementsShownEvent } from "../../../events/viewer/status_filter_events/not_built_elements_shown";
import { IN_PLACE_ELEMENTS_SHOWN, InPlaceElementsShownEvent } from "../../../events/viewer/status_filter_events/in_place_elements_shown";
import { DETECTED_DEVIATIONS_SHOWN, DetectedDeviationsShownEvent } from "../../../events/viewer/status_filter_events/detected_deviations_shown";
import { INCLUDED_ELEMENTS_SHOWN, IncludedElementsShownEvent } from "../../../events/viewer/status_filter_events/included_elements_shown";
import { NOT_BUILT_ELEMENTS_HIDDEN, NotBuiltElementsHiddenEvent } from "../../../events/viewer/status_filter_events/not_built_elements_hidden";
import { IN_PLACE_ELEMENTS_HIDDEN, InPlaceElementsHiddenEvent } from "../../../events/viewer/status_filter_events/in_place_elements_hidden";
import { DETECTED_DEVIATIONS_HIDDEN, DetectedDeviationsHiddenEvent } from "../../../events/viewer/status_filter_events/detected_deviations_hidden";
import { INCLUDED_ELEMENTS_HIDDEN, IncludedElementsHiddenEvent } from "../../../events/viewer/status_filter_events/included_elements_hidden";
import { VERIFIED_ELEMENTS_SHOWN, VerifiedElementsShownEvent } from "../../../events/viewer/status_filter_events/verified_elements_shown";
import { VERIFIED_ELEMENTS_HIDDEN, VerifiedElementsHiddenEvent } from "../../../events/viewer/status_filter_events/verified_elements_hidden";
import { DETECTED_OBSTRUCTING_ELEMENTS_SHOWN, DetectedObstructingElementsShownEvent } from "../../../events/viewer/status_filter_events/detected_obstructing_elements_shown";
import { DETECTED_OBSTRUCTING_ELEMENTS_HIDDEN, DetectedObstructingElementsHiddenEvent } from "../../../events/viewer/status_filter_events/detected_obstructing_elements_hidden";
import { EXCLUDE_FROM_ANALYSIS_ELEMENTS_HIDDEN, ExcludeFromAnalysisElementsHiddenEvent } from "../../../events/viewer/status_filter_events/exclude_from_analysis_elements_hidden";
import { EXCLUDE_FROM_ANALYSIS_ELEMENTS_SHOWN, ExcludeFromAnalysisElementsShownEvent } from "../../../events/viewer/status_filter_events/exclude_from_analysis_elements_shown";
import { MasterformatId } from "masterformat";
import { ISSUE_STATUS_FILTER_CHANGED, IssueStatusFilterChangedEvent } from "../../../events/viewer/issue_status_filter_changed";
import { MASTERFORMAT_FILTER_UPDATED, MasterformatFilterUpdatedEvent } from "../../../events/selection/work_breakdown_structure/masterformat_filter_updated";
import { VIEW_LOADED, ViewLoadedEvent } from "../../../events/loaded/view_loaded";
import { ANNOTATION_TITLE_FILTER_CHANGED, AnnotationTitleFilterChangedEvent } from "../../../events/viewer/annotation_title_filter_changed";
import { SHOW_UNCLASSIFIED_ELEMENTS_UPDATED, ShowUnclassifiedElementsUpdatedEvent } from "../../../events/show_unclassified_elements_updated";
import { MASTERFOMAT_FILTER_LOADED, MasterformatFilterLoadedEvent } from "../../../events/loaded/masterformat_filter_loaded";
import { INSPECTION_MODE_SELECTED, InspectionModeSelectedEvent } from "../../../events/selection/inspection_mode_selected";
import { InspectionMode } from "../../../models/domain/enums/inspection_modes";
import { REVIEW_MODE_TOGGLED_OFF, ReviewModeToggledOffEvent } from "../../../events/viewer/review_mode_toggled_off";
import { INCLUDE_IN_ANALYSIS_ELEMENTS_HIDDEN, IncludeInAnalysisElementsHiddenEvent } from "../../../events/viewer/status_filter_events/include_in_analysis_elements_hidden";
import { INCLUDE_IN_ANALYSIS_ELEMENTS_SHOWN, IncludeInAnalysisElementsShownEvent } from "../../../events/viewer/status_filter_events/include_in_analysis_elements_shown";

import type { Reducer } from "redux";
import type { Selected } from "type_aliases";


export type Level1Filter = { [code in Level1Id]: Selected } | Record<string, never>;
export type Level2Filter = { [code in Level2Id]: Selected } | Record<string, never>;
export type Level3Filter = { [code in Level3Id]: Selected } | Record<string, never>;
export type Level4Filter = { [code in Level4Id]: Selected } | Record<string, never>;
export type Level5Filter = { [code: string]: Selected } | Record<string, never>;
type Filters = Level1Filter | Level2Filter | Level3Filter | Level4Filter | Level5Filter;

export type MasterFormatClassLevel1Filter = { [code: string]: Selected } | Record<string, never>;
export type MasterFormatClassLevel2Filter = { [code: string]: Selected } | Record<string, never>;
export type MasterFormatClassLevel3Filter = { [code: string]: Selected } | Record<string, never>;
export type MasterFormatClassLevel4Filter = { [code: string]: Selected } | Record<string, never>;
export type MasterFormatClassLevel5Filter = { [code: string]: Selected } | Record<string, never>;
export type MasterFormatUnclassifiedFilter = { selected: 1 | 0 };

export type FilterType = "masterformat" | "uniformat";
type FormatId<T extends FilterType> = T extends "masterformat" ? MasterformatId : UniformatId;

export type FormatCodeTreeNode = {
  visited: boolean,
  value: Selected,
  children: FormatCodeTreeNode[],
  code: FormatId<any>
};

export type MasterformatFilter = {
  level1: MasterFormatClassLevel1Filter,
  level2: MasterFormatClassLevel2Filter,
  level3: MasterFormatClassLevel3Filter,
  level4: MasterFormatClassLevel4Filter,
  level5?: MasterFormatClassLevel5Filter,
  unclassified?: MasterFormatUnclassifiedFilter
}

export enum IssueStatusFilter {
  NONE = "NONE",
  UNEXPORTED = "UNEXPORTED",
  EXPORTED = "EXPORTED",
  EXPORTED_BIMTRACK_ONLY = "EXPORTED_BIMTRACK_ONLY",
  EXPORTED_BCF_ONLY = "EXPORTED_BCF_ONLY"
}

export interface FilterStore {
  toleranceFilter: number;
  issueStatusFilter: IssueStatusFilter;
  uniformat: UniformatFilterStore;
  onlyShowDeviationsFromCurrentScan: boolean;
  notBuiltElementsShown: boolean;
  inPlaceElementsShown: boolean;
  detectedDeviationsShown: boolean;
  detectedObstructingElementsShown: boolean;
  includedElementsShown: boolean;
  verifiedElementsShown: boolean;
  excludeFromAnalysisElementsShown: boolean;
  includeInAnalysisElementsShown: boolean;
  masterFormats: MasterformatFilter;
  trades: TradesFilterStore;
  annotationTitleFilter: string;
  showUnclassifiedElements: boolean;
}

export const defaultFilter: FilterStore = {
  uniformat: defaultUniformatFilter,
  toleranceFilter: 0.0762,
  issueStatusFilter: IssueStatusFilter.NONE,
  onlyShowDeviationsFromCurrentScan: false, // this does not affect anything, see store_in_local_storage.ts
  notBuiltElementsShown: false,
  inPlaceElementsShown: true,
  detectedDeviationsShown: true,
  detectedObstructingElementsShown: true,
  includedElementsShown: true,
  verifiedElementsShown: true,
  excludeFromAnalysisElementsShown: false,
  includeInAnalysisElementsShown: true,
  annotationTitleFilter: "",
  masterFormats: {
    level1: {},
    level2: {},
    level3: {},
    level4: {},
    level5: {},
  },
  trades: {
    unclassified: {
      id: "unclassified",
      selected: 1,
      parentId: null,
      childIds: []
    }
  },
  showUnclassifiedElements: true,
};

type FilterEvents =
  | IssueStatusFilterChangedEvent
  | DetectedObstructingElementsHiddenEvent
  | DetectedObstructingElementsShownEvent
  | DetectedDeviationsHiddenEvent
  | DetectedDeviationsShownEvent
  | DeviationsFromAllScansShownEvent
  | IncludedElementsHiddenEvent
  | IncludedElementsShownEvent
  | VerifiedElementsShownEvent
  | VerifiedElementsHiddenEvent
  | InPlaceElementsHiddenEvent
  | InPlaceElementsShownEvent
  | MasterformatFilterUpdatedEvent
  | NotBuiltElementsHiddenEvent
  | NotBuiltElementsShownEvent
  | ExcludeFromAnalysisElementsHiddenEvent
  | ExcludeFromAnalysisElementsShownEvent
  | IncludeInAnalysisElementsHiddenEvent
  | IncludeInAnalysisElementsShownEvent
  | OnlyDeviationsFromCurrentScanShownEvent
  | ToleranceFilterChangedEvent
  | ViewLoadedEvent
  | AnnotationTitleFilterChangedEvent
  | ShowUnclassifiedElementsUpdatedEvent
  | MasterformatFilterLoadedEvent
  | InspectionModeSelectedEvent
  | ReviewModeToggledOffEvent
  | UniformatFilterEvents
  | TradesFilterEvents;

const reduceFilter: Reducer<FilterStore, FilterEvents> = (filter = defaultFilter, event?) => {
  const trades = reduceTradesFilter(filter.trades, event as TradesFilterEvents);
  const uniformat = reduceUniformatFilter(filter.uniformat, event as UniformatFilterEvents);
  if (trades !== filter.trades) {
    filter = {
      ...filter,
      trades
    };
  }
  if (uniformat !== filter.uniformat) {
    filter = {
      ...filter,
      uniformat
    };
  }
  switch (event?.type) {
    case VIEW_LOADED: {
      return {
        ...filter,
        toleranceFilter: event.payload.viewAttributes.filters.deviationTolerance.value
      };
    }
    case MASTERFORMAT_FILTER_UPDATED: {
      return {
        ...filter,
        masterFormats: {
          ...event.payload.masterformatFilter
        }
      };
    }
    case TOLERANCE_FILTER_CHANGED: {
      return {
        ...filter,
        toleranceFilter: event.payload
      };
    }
    case ISSUE_STATUS_FILTER_CHANGED: {
      return {
        ...filter,
        issueStatusFilter: event.payload
      };
    }
    case ONLY_DEVIATIONS_FROM_CURRENT_SCAN_SHOWN: {
      return {
        ...filter,
        onlyShowDeviationsFromCurrentScan: true
      };
    }
    case DEVIATIONS_FROM_ALL_SCANS_SHOWN: {
      return {
        ...filter,
        onlyShowDeviationsFromCurrentScan: false
      };
    }
    case NOT_BUILT_ELEMENTS_SHOWN: {
      return {
        ...filter,
        notBuiltElementsShown: true
      };
    }
    case IN_PLACE_ELEMENTS_SHOWN: {
      return {
        ...filter,
        inPlaceElementsShown: true
      };
    }
    case DETECTED_DEVIATIONS_SHOWN: {
      return {
        ...filter,
        detectedDeviationsShown: true
      };
    }
    case DETECTED_OBSTRUCTING_ELEMENTS_SHOWN: {
      return {
        ...filter,
        detectedObstructingElementsShown: true
      };
    }
    case INCLUDED_ELEMENTS_SHOWN: {
      return {
        ...filter,
        includedElementsShown: true
      };
    }
    case REVIEW_MODE_TOGGLED_OFF: {
      return {
        ...filter,
        verifiedElementsShown: true
      };
    }
    case VERIFIED_ELEMENTS_SHOWN: {
      return {
        ...filter,
        verifiedElementsShown: true
      };
    }
    case NOT_BUILT_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        notBuiltElementsShown: false
      };
    }
    case IN_PLACE_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        inPlaceElementsShown: false
      };
    }
    case DETECTED_DEVIATIONS_HIDDEN: {
      return {
        ...filter,
        detectedDeviationsShown: false
      };
    }
    case DETECTED_OBSTRUCTING_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        detectedObstructingElementsShown: false,
      };
    }
    case INCLUDED_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        includedElementsShown: false
      };
    }
    case VERIFIED_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        verifiedElementsShown: false
      };
    }
    case EXCLUDE_FROM_ANALYSIS_ELEMENTS_SHOWN: {
      return {
        ...filter,
        excludeFromAnalysisElementsShown: true,
      };
    }
    case EXCLUDE_FROM_ANALYSIS_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        excludeFromAnalysisElementsShown: false,
      };
    }
    case INCLUDE_IN_ANALYSIS_ELEMENTS_SHOWN: {
      return {
        ...filter,
        includeInAnalysisElementsShown: true,
      };
    }
    case INCLUDE_IN_ANALYSIS_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        includeInAnalysisElementsShown: false,
      };
    }
    case ANNOTATION_TITLE_FILTER_CHANGED: {
      return {
        ...filter,
        annotationTitleFilter: event.payload
      };
    }
    case SHOW_UNCLASSIFIED_ELEMENTS_UPDATED: {
      return {
        ...filter,
        showUnclassifiedElements: event.payload
      };
    }
    case MASTERFOMAT_FILTER_LOADED: {
      return {
        ...filter,
        masterFormats: {
          ...event.payload
        }
      };
    }
    case INSPECTION_MODE_SELECTED: {
      const onlyShowDeviationsFromCurrentScan = event.payload === InspectionMode.inspect;
      return {
        ...filter,
        onlyShowDeviationsFromCurrentScan,
      };
    }
    default: {
      return filter;
    }
  }
};

export default reduceFilter;

export const toggleParents = (node: FormatCodeTreeNode, type?: FilterType) => {
  if (node.children.length === 0) {
    node.visited = true;
  } else if (node.visited === false) {
    if (canComputeValue(node)) {
      node.value = type === "masterformat" ? computeMasterformatValue(node) : computeValue(node);
      node.visited = true;
    } else {
      node.children.forEach(child => toggleParents(child, type));
    }
    node.value = type === "masterformat" ? computeMasterformatValue(node) : computeValue(node);
    node.visited = true;
  }
};

const canComputeValue = (treeNode: FormatCodeTreeNode): boolean => {
  return _.every(treeNode.children, (node) => node.visited === true);
};

const computeValue = (treeNode: FormatCodeTreeNode): Selected => {
  if (_.every(treeNode.children, (node => node.value === 1))) {
    return 1;
  } else if (_.every(treeNode.children, (node => node.value === 0))) {
    return 0;
  } else {
    return -1;
  }
};

const computeMasterformatValue = (treeNode: FormatCodeTreeNode): Selected => {
  if (_.every(treeNode.children, (node => node.value === 1))) {
    return 1;
  } else if (_.every(treeNode.children, (node => node.value === 0))) {
    return 0;
  } else {
    return -1;
  }
};

export function toggleChildTrades<Trade extends Filters | MasterformatFilter>(code: UniformatId | MasterformatId, newValue: Selected, trades: Trade): Trade {
  return _.mapObject(trades, (originalValue, childCode: UniformatId | MasterformatId) => {
    if ((childCode as string).startsWith(code)) {
      return newValue;
    } else {
      return originalValue;
    }
  }) as Trade;
}
