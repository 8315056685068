import hxAuthUserSignedIn from "../../events/auth_events/hxauth_user_signed_in";
import getHxAuthUser from "../../services/getters/user_getters/get_hxauth_user";
import HxAuthApi from "../../services/hx_auth/hx_auth_api";
import signOutUser from "../sign_in_page/sign_out_user";

const refreshHxAuthToken = () => {
  return (dispatch, getState) => {
    const hxAuthUser = getHxAuthUser(getState(), {});
    const refreshTime = hxAuthUser.expireTime - 30;
    const now = Date.now() / 1000;
    if (now < refreshTime) {
      return Promise.resolve();
    }

    const realmName = hxAuthUser.realmName;
    // Leave some messages to help debug refresh token issues
    console.debug(`refreshHxAuthToken: refreshing for ${realmName}`);
    return HxAuthApi.refreshAccessToken(realmName, hxAuthUser.refreshToken).then(({
      accessToken,
      refreshToken}) => {
      console.debug("refreshHxAuthToken: refresh ok");
      window.localStorage.setItem("hxauth", JSON.stringify({accessToken, refreshToken, realmName,}));
      dispatch(hxAuthUserSignedIn({accessToken, refreshToken, realmName}));
    }).catch(() => {
      console.debug("refreshHxAuthToken: refresh fail");
      return signOutUser()(dispatch, getState);
    });
  };
};

export default refreshHxAuthToken;
