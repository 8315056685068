
import avvir, {FirebaseUser,BasicUser,GatewayUser, UserRole, User} from "avvir";

/** @deprecated Import these directly from "avvir" instead */
export {GatewayUser, UserRole};
/** @deprecated Import these directly from "avvir" instead */
export type { FirebaseUser, BasicUser, User };

const getAuthorizationHeaders = (user: User) => {
  return avvir.api.auth.getAuthorizationHeaders(user);
};

/** @deprecated Use equivalent from "avvir" instead */
export default getAuthorizationHeaders;
