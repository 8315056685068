import Avvir, { ApiUserPermission } from "avvir";

import getUser from "../../services/getters/base_getters/get_user";
import { getUserId } from "../../services/getters/user_getters/get_user_id";
import makeEventfulAction, { AvvirThunk } from "../make_eventful_action";
import userPermissionsLoaded, { UserPermissionsLoadedEvent } from "../../events/auth_events/user_permissions_loaded";
import { ApiFailureEvent } from "../../events/notifications/failures/api_failure";

const loadUserPermissions = (): AvvirThunk<Promise<ApiUserPermission[]>, UserPermissionsLoadedEvent | ApiFailureEvent> => {
  return (dispatch, getState) => {
    const user = getUser(getState(), {});
    const userId = getUserId(getState(), {});
    if (userId != null) {
      return Avvir.api.user.getUserPermissions(userId, user)
        .then((permissions) => {
          dispatch(userPermissionsLoaded(permissions));
          return permissions;
        });
    }

    dispatch(userPermissionsLoaded([]));
    return Promise.resolve([]);
  };
};

export default makeEventfulAction<Promise<ApiUserPermission[]>, UserPermissionsLoadedEvent | ApiFailureEvent, typeof loadUserPermissions>("loadUserPermissions",
  loadUserPermissions);
