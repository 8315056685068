import { AvvirRoutingEvent } from "type_aliases";

export type ToSuperadminUserEvent = AvvirRoutingEvent<typeof TO_SUPERADMIN_USER, {email: string, userId: number}>

const toSuperadminUser = (email: string, userId: number): ToSuperadminUserEvent => ({
  type: TO_SUPERADMIN_USER,
  payload: { email, userId }
});

export const TO_SUPERADMIN_USER = "to_superadmin_user";

export default toSuperadminUser;
