import { Reducer } from "redux";
import _ from "underscore";

import level1 from "../../../../resources/uniformat/level_1.json";
import level2 from "../../../../resources/uniformat/level_2.json";
import level3 from "../../../../resources/uniformat/level_3.json";
import level4 from "../../../../resources/uniformat/level_4.json";

import UniformatConverter from "../../converters/uniformat_converter";
import { FormatCodeTreeNode, toggleChildTrades, toggleParents } from "./reduce_filter";
import { TRADE_HIDDEN, TradeHiddenEvent } from "../../../events/selection/trades/trade_hidden";
import { TRADE_HIDDEN_ALL, TradeHiddenAllEvent } from "../../../events/selection/trades/trade_hidden_all";
import { TRADE_SHOWN, TradeShownEvent } from "../../../events/selection/trades/trade_shown";
import { TRADE_SHOWN_ALL, TradeShownAllEvent } from "../../../events/selection/trades/trade_shown_all";
import { TRADE_SHOWN_ONLY, TradeShownOnlyEvent } from "../../../events/selection/trades/trade_shown_only";
import { VIEW_LOADED, ViewLoadedEvent } from "../../../events/loaded/view_loaded";

import type { Selected } from "type_aliases";
import type { Level1Id, Level2Id, Level3Id, Level4Id, UniformatId } from "uniformat";

export type Level1Filter = { [code in Level1Id]: Selected } | Record<string, never>;
export type Level2Filter = { [code in Level2Id]: Selected } | Record<string, never>;
export type Level3Filter = { [code in Level3Id]: Selected } | Record<string, never>;
export type Level4Filter = { [code in Level4Id]: Selected } | Record<string, never>;

type LevelNFilter<N extends number> = [
  never,
  Level1Filter,
  Level2Filter,
  Level3Filter,
  Level4Filter,
][N]

type LevelNCode<N extends number> = [
  never,
  Level1Id,
  Level2Id,
  Level3Id,
  Level4Id,
][N];



export type UniformatFilterEvents =
  | TradeHiddenAllEvent
  | TradeHiddenEvent
  | TradeShownAllEvent
  | TradeShownEvent
  | TradeShownOnlyEvent
  | ViewLoadedEvent;

export type UniformatFilterStore = {
  level1: Level1Filter
  level2: Level2Filter
  level3: Level3Filter
  level4: Level4Filter
}

export const defaultUniformatFilter: UniformatFilterStore = {
  level1: _.mapObject(level1, () => 1) as Level1Filter,
  level2: _.mapObject(level2, () => 1) as Level2Filter,
  level3: _.mapObject(level3, () => 1) as Level3Filter,
  level4: _.mapObject(level4, () => 1) as Level4Filter,
};

const reduceUniformatFilter: Reducer<UniformatFilterStore, UniformatFilterEvents> = (uniformatFilter = defaultUniformatFilter, event = { type: null }) => {
  switch (event.type) {
    case TRADE_HIDDEN: {
      return toggleUniformatTrade(event.payload.code as UniformatId, 0, uniformatFilter);
    }
    case TRADE_SHOWN: {
      return toggleUniformatTrade(event.payload.code as UniformatId, 1, uniformatFilter);
    }
    case TRADE_HIDDEN_ALL: {
      return toggleAllTrades(uniformatFilter, 0);
    }
    case TRADE_SHOWN_ALL: {
      return toggleAllTrades(uniformatFilter, 1);
    }
    case VIEW_LOADED: {
      return {
        ...toggleTrades(event.payload.viewAttributes.filters.trades.shownUniformatCodes, uniformatFilter),
      };
    }
    case TRADE_SHOWN_ONLY: { // unused currently (future story)
      return toggleOtherTrades(event.payload.code as UniformatId, uniformatFilter);
    }
    default: {
      return uniformatFilter;
    }
  }
};

const toggleUniFormat = (code: UniformatId, value: Selected, filter: UniformatFilterStore): UniformatFilterStore => {
  const uniformatLevel = UniformatConverter.getUniformatLevel(code);
  let level1: Level1Filter, level2: Level2Filter, level3: Level3Filter, level4: Level4Filter;
  if (uniformatLevel === 4) {
    level1 = { ...filter.level1 };
    level2 = { ...filter.level2 };
    level3 = { ...filter.level3 };
    level4 = { ...filter.level4 };
    level4[code] = value;
  } else {
    level1 = toggleChildTrades(code as Level1Id, value, filter.level1);
    level2 = toggleChildTrades(code as Level2Id, value, filter.level2);
    level3 = toggleChildTrades(code as Level3Id, value, filter.level3);
    level4 = toggleChildTrades(code as Level4Id, value, filter.level4);
  }
  return { level1, level2, level3, level4 };
};


export function toggleUniformatTrade(code: UniformatId, value: Selected, filter: UniformatFilterStore): UniformatFilterStore {
  let level1, level2, level3, level4, tempFilter, formatTree: Partial<FormatCodeTreeNode>[];
  tempFilter = toggleUniFormat(code as UniformatId, value, filter);
  //@ts-ignore
  formatTree = createUniFormatTree(tempFilter as UniformatFilter);
  formatTree.forEach(node => {
    toggleParents(node as FormatCodeTreeNode, "uniformat");
  });

  //@ts-ignore
  level1 = _.object(_.map(formatTree, tree => [tree.code, tree.value]));
  const level2Tree = _.flatten(_.map(formatTree, tree => tree.children));
  level2 = _.object(_.map(level2Tree, tree => [tree.code, tree.value]));
  const level3Tree = _.flatten(_.map(level2Tree, tree => tree.children));
  level3 = _.object(_.map(level3Tree, tree => [tree.code, tree.value]));
  const level4Tree = _.flatten(_.map(level3Tree, tree => tree.children));
  level4 = _.object(_.map(level4Tree, tree => [tree.code, tree.value]));
  return { ...filter, level1, level2, level3, level4 };
}

const toggleAllTrades = (filter: UniformatFilterStore, value: Selected): UniformatFilterStore => {
  return _.reduce(level1, (filterSoFar, level1Description, level1Id: UniformatId) => {
    return toggleUniformatTrade(level1Id, value, filterSoFar);
  }, filter);
};

const toggleTrades = (trades: UniformatId[], filter: UniformatFilterStore): UniformatFilterStore => {
  if (trades.length === 0) {
    return defaultUniformatFilter;
  }
  const returnFilter = {
    ...filter,
    level1: _.mapObject(level1, () => 0) as Level1Filter,
    level2: _.mapObject(level2, () => 0) as Level2Filter,
    level3: _.mapObject(level3, () => 0) as Level3Filter,
    level4: _.mapObject(level4, () => 0) as Level4Filter
  };

  return trades.reduce((filterSoFar, trade) => toggleUniformatTrade(trade, 1, filterSoFar), returnFilter);
};

const toggleOtherTrades = (code: UniformatId, filter: UniformatFilterStore): UniformatFilterStore => {
  let filterResult = filter;
  _(filter.level1).keys().forEach((codeToDeselect: Level1Id) => {
    if (codeToDeselect !== code) {
      filterResult = toggleUniformatTrade(codeToDeselect, 0, filterResult);
    }
  });
  if (filter.level1[code] === 0) {
    filterResult = toggleUniformatTrade(code, 1, filterResult);
  }

  return filterResult;
};

function createUniFormatTree(filter: UniformatFilterStore) {
  return _(filter.level1).map((level1value: Selected, level1code: LevelNCode<1>) => {
    return {
      visited: false,
      value: level1value,
      code: level1code,
      children: _.map<LevelNFilter<2>>(
        // @ts-ignore
        _.pick<Selected, LevelNFilter<2, T>>(filter.level2, (level2value: Selected, level2code: LevelNCode<2, T>) => {
          return (level2code as string).startsWith(level1code);
        }),
        (level2value, level2code: LevelNCode<2>) => {
          return {
            visited: false,
            value: level2value,
            code: level2code,
            // @ts-ignore
            children: _.map(_.pick<Selected, LevelNFilter<3>>(filter.level3, (level3value, level3code: LevelNCode<3, T>) => {
              return (level3code as string).startsWith(level2code);
            }), (level3value, level3code: LevelNCode<3>) => {
              return {
                visited: false,
                value: level3value,
                code: level3code,
                // @ts-ignore
                children: _.map(_.pick<Selected, LevelNFilter<4, T>>(filter.level4, (level4value, level4code: LevelNCode<4, T>) => {
                  return (level4code as string).startsWith(level3code);
                }), (level4value, level4code: LevelNCode<4>) => {
                  return {
                    visited: false,
                    value: level4value,
                    code: level4code,
                    children: []
                  };
                })
              };
            })
          };
        })
    };
  });
}


export default reduceUniformatFilter;

