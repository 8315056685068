import { AvvirEvent } from "type_aliases";

export type HxAuthUserSignedInEvent = AvvirEvent<typeof HXAUTH_USER_SIGNED_IN, { accessToken: string, refreshToken: string, realmName: string }>

const hxAuthUserSignedIn = (params: {realmName: string, accessToken: string, refreshToken: string}): HxAuthUserSignedInEvent => ({
  type: HXAUTH_USER_SIGNED_IN,
  payload: params
});

export const HXAUTH_USER_SIGNED_IN = "hxauth_user_signed_in";

export default hxAuthUserSignedIn;
