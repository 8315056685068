import _ from "underscore";
import { ApiProject } from "avvir";

import Project from "../../models/domain/project";

export default class ProjectConverter {
  static fromApi = (apiProject: Partial<ApiProject>): Partial<Project> => {
    const {
      city,
      country,
      addressLine1,
      addressLine2,
      state,
      zip,
      defaultFirebaseFloorId,
      endDate,
      firebaseId,
      name,
      notes,
      pricing,
      startDate,
      firebaseFloorIds,
      systemOfMeasurement,
      archivedAt,
      progressNotes,
      avvirAnalysisNotes,
      sourceAnalysisNotes,
      id,
      clientAccountId: organizationId,
      scannedProjectMasterformatProgresses,
      baselineProjectMasterformatProgresses,
      currentProjectMasterformatProgresses,
      costAnalysisProgresses,
      projectReports,
      generateMasterformatProgressEnabled,
      teamMembers,
      integrationProjectId,
      settings,
      baselineScheduleDate,
      currentScheduleDate
    } = apiProject;
    return _.omit({
      ...new Project({
        addressCity: city,
        addressCountry: country,
        addressLine1,
        addressLine2,
        addressState: state,
        addressZip: zip,
        defaultFirebaseFloorId,
        endDate,
        firebaseId,
        name,
        notes,
        pricing,
        startDate,
        firebaseFloorIds,
        systemOfMeasurement,
        archivedAt,
        progressNotes,
        avvirAnalysisNotes,
        sourceAnalysisNotes,
        id,
        firebaseOrganizationId: organizationId,
        scannedProjectMasterformatProgresses,
        baselineProjectMasterformatProgresses,
        currentProjectMasterformatProgresses,
        // @ts-ignore
        costAnalysisProgresses,
        projectReports,
        generateMasterformatProgressEnabled,
        teamMembers,
        integrationProjectId,
        settings: settings,
        baselineScheduleDate,
        currentScheduleDate
      })
    }, (value) => !value);
  };

  static toApiProject(project: Partial<Project>): ApiProject {
    const {
      addressCity: city,
      addressCountry: country,
      addressState: state,
      addressZip: zip,
      files,
      photoAreas,
      masterformatProgress,
      firebaseOrganizationId: clientAccountId,
      generateMasterformatProgressEnabled,
      ...restOfProject
    } = project;
    return new ApiProject({ city, country, state, zip, clientAccountId, generateMasterformatProgressEnabled, ...restOfProject });
  }
}